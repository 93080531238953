import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './PlansTable.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight, faPlus, faCheck} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import * as mainStyle from "../../index.module.scss";
import "../../swiperStuff.scss"
import "./swiper.scss"

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

type PlansTableProps = {
    title: string,
    description: string,
    bottomDescription: string,
    plans: []

}


function PlansTable(props: PlansTableProps) {
    const breakpoints = {
        1390: {
            slidesPerView: 4
        },
        991: {
            slidesPerView: 3
        },
        767: {
            slidesPerView: 2
        },
        0: {
            slidesPerView: 1
        }
    };
    return <>
        <div>
            <Row className={mainStyle.box + style.plansBox}>
                <div className={contentStyle.contentElement + " " + style.customHeader}>
                    <h2 className={"text-center " + style.headline}>{props.title}</h2>
                    <div className={style.description}>{props.description}</div>
                </div>
                <Row className={"gx-4"}>
                    <Swiper
                        navigation={true}
                        slidesPerView={3}
                        modules={[Navigation]}
                        breakpoints={breakpoints}
                        className={style.mySwiper + " planTableSwiper"}
                    >
                        {props.plans ?
                            props.plans.map((item,key) =>
                            <SwiperSlide className={style.swiperSlide}>
                                <div className={style.planContainer}>
                                    <div className={style.planHeaderSection} style={{backgroundColor: item.Color}}>
                                        <Row className={"d-flex justify-content-center align-items-center"}>
                                            <Col xs={2} className={style.headerIcon}>
                                                <img src={item.ImgLink}
                                                             className={style.icon}
                                                             placeholder={"traceSVG"} alt={"background"}/>
                                            </Col>
                                            <Col xs={10}>
                                                <div className={style.name}>
                                                    {item.Title}
                                                </div>
                                                <div className={style.price}>
                                                    {item.Price}<span className={style.month}> / Monat </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={style.planSection}>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}>{item.Plus1}</Col>
                                        </Row>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}><b>{item.Plus2}</b></Col>
                                        </Row>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}>{item.Plus3}</Col>
                                        </Row>
                                    </div>
                                    <div className={style.divider}/>
                                    <div className={style.planSection}>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}>{item.Check1}</Col>
                                        </Row>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}><b>{item.Check2}</b></Col>
                                        </Row>
                                        <Row className={style.item}>
                                            <Col xs={2} className={"d-flex align-items-center justify-content-end " + style.iconContainer}>
                                                <div className={style.customIcon}>
                                                    <FontAwesomeIcon icon={faCheck} className={' font-bolder'}/>
                                                </div>
                                            </Col>
                                            <Col xs={10} className={style.listHeadline}>{item.Check3}</Col>
                                        </Row>
                                    </div>
                                    <div className={style.planSection + " " + style.lastSection}>
                                        <div className={"d-flex"}>
                                            <div className={style.background}>
                                                <div style={{backgroundColor: item.Color}}
                                                     className={style.iconContainer + ' ' + style.buttonIcon}>
                                                    <img src={item.ImgLink}

                                                                 placeholder={"traceSVG"} alt={"background"}/>
                                                </div>
                                            </div>
                                            <div className={style.textContainer} style={{color: item.Color}}>
                                                <div>
                                                    Onlineshop {item.Title} werden
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> ): null}
                    </Swiper>
                </Row>
                <Row className={"d-flex justify-content-center align-items-center flex-column " + style.bottomContainer}>
                    <div className={style.bottomDescription}>{props.bottomDescription}</div>
                    <a href={'#detailTable'} className={style.formBTN + " my-4"}>
                       <span> Alle Details zu den Paketen</span>
                    </a>
                </Row>
            </Row>
        </div>
    </>
        ;
}

export default PlansTable;
