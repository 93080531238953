// extracted by mini-css-extract-plugin
export var background = "PlansTable-module--background--ti47E";
export var bottomContainer = "PlansTable-module--bottomContainer--iU-Sp";
export var bottomDescription = "PlansTable-module--bottomDescription--rov52";
export var buttonIcon = "PlansTable-module--buttonIcon--hyF85";
export var customHeader = "PlansTable-module--customHeader--jsLbC";
export var customIcon = "PlansTable-module--customIcon--4OWmF";
export var description = "PlansTable-module--description---+MzR";
export var divider = "PlansTable-module--divider--L2uww";
export var formBTN = "PlansTable-module--formBTN--c3WVY";
export var headerIcon = "PlansTable-module--headerIcon--j0Zta";
export var headline = "PlansTable-module--headline--14gFp";
export var icon = "PlansTable-module--icon--9boEp";
export var iconContainer = "PlansTable-module--iconContainer--GZNT+";
export var image = "PlansTable-module--image--0Xnfz";
export var item = "PlansTable-module--item--JN6Kn";
export var lastSection = "PlansTable-module--lastSection--TJESH";
export var listHeadline = "PlansTable-module--listHeadline--tu83M";
export var listHeadlineBold = "PlansTable-module--listHeadlineBold--bvw6m";
export var month = "PlansTable-module--month--0bHzn";
export var name = "PlansTable-module--name--LLlpa";
export var planContainer = "PlansTable-module--planContainer--vuA-o";
export var planHeaderSection = "PlansTable-module--planHeaderSection--FHMXD";
export var planSection = "PlansTable-module--planSection--B7gz7";
export var plansBox = "PlansTable-module--plansBox--djqAU";
export var price = "PlansTable-module--price--SmIWR";
export var swiperSlide = "PlansTable-module--swiperSlide--XzWZA";
export var textContainer = "PlansTable-module--textContainer--GH+f8";